<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    item-text="name"
    item-value="id"
    :search-input.sync="search"
    :label="isMultiple ? $tc(label, 2) : $tc(label, 1)"
    :multiple="isMultiple"
    :disabled="hasDisabledInput"
    :clearable="isClearable"
    outlined
    hide-details
    :return-object="returnObject"
    :rules="isRequired ? requiredRules : []"
    :validate-on-blur="isRequired"
    @input="onInput"
  ></v-autocomplete>
</template>

<script>
import formRules from "@/mixins/formRules";

export default {
  name: "SelectBrandCollection",

  mixins: [formRules],

  props: {
    isRequired: { type: Boolean, default: false },
    isClearable: { type: Boolean, default: true },
    isMultiple: { type: Boolean, default: false },
    hasDisabledInput: { type: Boolean, default: false },
    // eslint-disable-next-line vue/require-prop-type-constructor
    value: { type: String | Object, default: () => null },
    label: { type: String, default: "collections.selectCollection.label" },
    returnObject: { type: Boolean, default: true },
    accountId: { type: String, default: null },
  },

  components: {},

  data() {
    return {
      items: [],
      isLoading: false,
      model: null,
      search: null,
      timerId: null,
      timerCount: 500,
    };
  },

  created() {
    this.getItems();
  },

  methods: {
    getItems() {
      this.isLoading = true;
      this.$http
        .get(
          `/brand-collections?search=${this.search}&accounts[]=${this.accountId}`,
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.items = res.data.data;
          this.model = this.value;

          if (this.model) {
            // is Array
            if (Array.isArray(this.model)) {
              this.items.push(
                ...this.model.filter(
                  (el) => this.items.findIndex((el2) => el2.id === el.id) === -1
                )
              );
            } else {
              if (
                this.items.findIndex((el2) => el2.id === this.model.id) === -1
              )
                this.items.push(this.model);
            }
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

    onInput(val) {
      this.$emit("input", val);
      this.search = "";
    },
  },
  watch: {
    search(val) {
      const modelStr =
        this.model && typeof this.model === "object" && this.model["name"]
          ? this.model.name
          : this.model;
      if (val && val !== modelStr && !this.isLoading) {
        this.isLoading = true;
        this.timerId = setTimeout(() => {
          this.getItems();
        }, this.timerCount);
      }
    },
    accountId() {
      this.getItems();
    },
  },
};
</script>

<style scoped></style>
